import {
  faAddressCard,
  faHelmetSafety,
  faLaptopCode,
  faPersonWalkingDashedLineArrowRight,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  InvitationDtoStateEnum,
  InvitationDtoTypeEnum,
  InvitationTrainingResponse,
  InvitedPersonDto,
  SafetyEquipmentDto,
  SafetyEquipmentDtoTypeEnum,
  TourniquetResponse,
  UpdateInvitedPersonRequest,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { FC, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../store';
import { selectIdentity } from '../../../../store/entities/identity';
import { tourniquetIdentityActions } from '../../../../store/entities/tourniquetIdentity';
import { tourniquetTrainingsActions } from '../../../../store/entities/tourniquetTrainings';
import { invitationsApi } from '../../../../utils/api';
import { getCustomError, setErrorSnacks } from '../../../../utils/error';
import {
  getFirstLastNameObj,
  getLangNameObj,
  getWorkplaceNameObj,
  invitaitonTrainingIsValid,
  invitationCertificateIsApproved,
  invitationCertificateIsExpired,
  invitationTrainingIsCompleted,
  joinStrings2,
} from '../../../../utils/format';
import { hasRole, hasOtherRoleThan, isExternal } from '../../../../utils/roles';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import {
  InnerTooltipWrapper,
  QcsTooltip,
} from '../../../common/basic/QcsTooltip';
import { FaIcon } from '../../../common/FaIcon';
import { GridList } from '../../../common/grid/GridList';
import { SectionHeader } from '../../../common/SectionHeader';
import {
  InvitedPersonsAddModal,
  InvitedPersonsAddModalData,
} from './InvitedPersonsAddModal';
import { InvitedPersonsDocumentModal } from './InvitedPersonsDocumentModal';
import { InvitedPersonsEditModal } from './InvitedPersonsEditModal';
import { InvitedPersonsOverride } from './InvitedPersonsOverride';
import { InvitedPersonsPinModal } from './InvitedPersonsPinModal';
import { InvitedPersonsDeleteModal } from './InvitedPersonsDeleteModal';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { InvitedPersonsSafetyEquipmentModal } from './InvitedPersonsSafetyEquipmentModal';
import {
  tourniquetGetInvitationPerson,
  tourniquetLoadData,
} from '../../../tourniquet/tourniquetFunctions';
import { QcsTypography } from '../../../common/basic/QcsTypography';
import { selectCompanyCustomization } from '../../../../store/entities/companyCustomization';
import _ from 'lodash';
import { useInvitation } from './invitationFunctions';

export const InvitedPersonsTable: FC = () => {
  const {
    t,
    i18n,
    invitation,
    reloadInvitation,
    dispatch,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedData, setEditedData] = useState<InvitedPersonDto>();
  const [personToDelete, setPersonToDelete] = useState<InvitedPersonDto>();
  const [personOverride, setPersonOverride] = useState<InvitedPersonDto>();
  const [invitedPersonForPin, setInvitedPersonForPin] =
    useState<InvitedPersonDto>();
  const [personSafetyEquipments, setPersonSafetyEquipments] =
    useState<InvitedPersonDto>();
  const [checkedItems, setCheckedItems] = useState<InvitedPersonDto[]>([]);
  const [documentDataId, setDocumentDataId] = useState<string>();
  const theme = useTheme();
  const navigate = useNavigate();

  const isOneTime =
    invitation.type === InvitationDtoTypeEnum.OneTime ||
    invitation.type === InvitationDtoTypeEnum.OneTimeEntry ||
    hasRole(identity.roles, [UserDtoRolesEnum.ExternalWorker]);

  const handleEdit = (item: InvitedPersonDto) => {
    setEditedData(item);
  };

  const handleEditSave = async (data: InvitedPersonDto) => {
    const data2: UpdateInvitedPersonRequest = {
      activities: data.activities.map((x) => x.id),
      workplaces: data.workplaces.map((x) => x.id),
    };

    await invitationsApi.updatePerson(invitation.id!, data.id!, data2);
    await reloadInvitation();
  };

  const handleEditClose = () => {
    setEditedData(undefined);
  };

  const handleUploadDocument = (item: InvitedPersonDto) => {
    setDocumentDataId(item.id);
  };

  const handleDocumentClose = () => {
    setDocumentDataId(undefined);
  };

  const handleShowPersonOverride = (item: InvitedPersonDto) => {
    setPersonOverride(item);
  };

  const handlePersonOverrideClose = () => {
    setPersonOverride(undefined);
  };

  const handleShowSafetyEquipments = (item: InvitedPersonDto) => {
    setPersonSafetyEquipments(item);
  };

  const handleCloseSafetyEquipments = () => {
    setPersonSafetyEquipments(undefined);
  };

  const handleShowPin = (item: InvitedPersonDto) => {
    setInvitedPersonForPin(item);
  };

  const handlePinClose = () => {
    setInvitedPersonForPin(undefined);
  };

  const handleDeletePerson = (item: InvitedPersonDto) => {
    setPersonToDelete(item);
  };

  const handleCloseDeletePerson = () => {
    setPersonToDelete(undefined);
  };

  const handleStartTestItem = async (item: InvitedPersonDto) => {
    await startTest([item]);
  };

  const handleRenderData = (item: InvitedPersonDto) => {
    if (
      hasRole(identity.roles, [UserDtoRolesEnum.ExternalWorker]) &&
      item.employeeId !== identity.id
    )
      return <></>;
    const editEmployeeDisabled = isSaving;
    const startTestDisabled =
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerWorkplace,
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.Receptionist,
        UserDtoRolesEnum.ExternalManager,
        UserDtoRolesEnum.ExternalWorker,
      ]) ||
      isSaving ||
      invitation.state === InvitationDtoStateEnum.Archived ||
      (item.trainings?.length ?? 0) < 1;
    const startTestOk =
      !startTestDisabled &&
      !item.trainings!.some((x) => !invitationTrainingIsCompleted(x)) &&
      !item.trainings!.some((x) => !invitaitonTrainingIsValid(x));
    const uploadDocumentDisabled =
      isSaving || (item.documents?.length ?? 0) < 1;
    const uploadDocumentOk = isExternal(identity)
      ? !uploadDocumentDisabled &&
        item.documents!.every(
          (x) => !!x.document && invitationCertificateIsExpired(x)
        )
      : !uploadDocumentDisabled &&
        //All must be uploaded and approved.
        item.documents!.every(
          (x) => !!x.document && invitationCertificateIsApproved(x)
        );
    const passEntraceDisabled =
      isSaving ||
      //Already overridden - contains date time in ISO format.
      !!item.conditionOverridden ||
      !hasOtherRoleThan(identity.roles, [UserDtoRolesEnum.ExternalManager]);
    const safetyEquipmentsForPerson = ([] as SafetyEquipmentDto[]).concat(
      ...(invitation.workplaces ?? [])
        .filter((workplace) =>
          item.workplaces.some((pw) => pw.id === workplace.workplace?.id)
        )
        .map((workplace) => workplace?.safetyEquipments ?? [])
    );
    //Any workplace in person must have safety equipment or disabled.
    const showSafetyEquipmentDisabled = safetyEquipmentsForPerson.length === 0;
    const showSafetyEquipmentOk =
      !showSafetyEquipmentDisabled &&
      !safetyEquipmentsForPerson.some(
        (x) => x.type === SafetyEquipmentDtoTypeEnum.Owned
      );
    const showEmployeePinDisabled = isSaving;
    const deleteEmployeeDisabled =
      isOneTime ||
      isSaving ||
      invitation.state === InvitationDtoStateEnum.Archived ||
      (invitation.persons ?? []).length < 2;

    const handleRowClick = () => {
      if (isOneTime) {
        return;
      }

      if (
        hasRole(identity.roles, [
          UserDtoRolesEnum.AdminQcs,
          UserDtoRolesEnum.AdminCompany,
          UserDtoRolesEnum.ManagerOsah,
          UserDtoRolesEnum.ManagerWorkplace,
          UserDtoRolesEnum.Receptionist,
        ])
      ) {
        navigate(
          `/supplier/${invitation.supplier?.id}/employee/${item.employeeId}`,
          {
            state: { back: `/invitation/${invitation.id}/person` },
          }
        );
      } else if (hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])) {
        navigate(`/supplier/employee/${item.employeeId}`, {
          state: { back: `/invitation/${invitation.id}/person` },
        });
      }
    };

    return (
      <>
        <QcsTableCell onClick={handleRowClick}>{item.name}</QcsTableCell>
        <QcsTableCell onClick={handleRowClick}>{item.lastName}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {item.documents?.filter((x) => !!x.document).length ?? 0}
        </QcsTableCell>
        <QcsTableCell align="right">
          {/* EDIT EMPLOYEE */}
          {!isOneTime && (
            <QcsTooltip
              title={t('invitation.tabs.tooltips.editEmployee')}
              placement="top"
            >
              <InnerTooltipWrapper>
                <QcsButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEdit(item);
                  }}
                  disabled={editEmployeeDisabled}
                >
                  <ManageAccountsOutlinedIcon
                    color={editEmployeeDisabled ? undefined : 'success'}
                  />
                </QcsButton>
              </InnerTooltipWrapper>
            </QcsTooltip>
          )}
          {hasRole(identity.roles, [
            UserDtoRolesEnum.AdminQcs,
            UserDtoRolesEnum.ManagerWorkplace,
            UserDtoRolesEnum.ManagerOsah,
            UserDtoRolesEnum.Receptionist,
            UserDtoRolesEnum.ExternalManager,
            UserDtoRolesEnum.ExternalWorker,
          ]) && (
            <QcsTooltip
              title={t('invitation.tabs.tooltips.startTest')}
              placement="top"
            >
              <InnerTooltipWrapper>
                <QcsButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleStartTestItem(item);
                  }}
                  disabled={startTestDisabled}
                >
                  <FaIcon
                    icon={faLaptopCode}
                    color={
                      startTestDisabled
                        ? undefined
                        : startTestOk
                        ? theme.palette.success.main
                        : theme.palette.warning.main
                    }
                  />
                </QcsButton>
              </InnerTooltipWrapper>
            </QcsTooltip>
          )}
          {/* UPLOAD DOCUMENT */}
          <QcsTooltip
            title={t('invitation.tabs.tooltips.uploadDocument')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleUploadDocument(item);
                }}
                disabled={uploadDocumentDisabled}
              >
                <FaIcon
                  icon={faAddressCard}
                  color={
                    uploadDocumentDisabled
                      ? undefined
                      : uploadDocumentOk
                      ? theme.palette.success.main
                      : theme.palette.warning.main
                  }
                />
              </QcsButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
          {/* PASS ENTRANCE */}
          {!isOneTime && (
            <QcsTooltip
              title={t('invitation.tabs.tooltips.passEntrance')}
              placement="top"
            >
              <InnerTooltipWrapper>
                <QcsButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleShowPersonOverride(item);
                  }}
                  disabled={passEntraceDisabled}
                >
                  <FaIcon
                    icon={faPersonWalkingDashedLineArrowRight}
                    color={
                      passEntraceDisabled
                        ? undefined
                        : theme.palette.success.main
                    }
                  />
                </QcsButton>
              </InnerTooltipWrapper>
            </QcsTooltip>
          )}
          {/* Show safety equipments */}
          <QcsTooltip
            title={t('invitation.persons.safetyEquipmentTitle')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleShowSafetyEquipments(item);
                }}
                disabled={showSafetyEquipmentDisabled}
              >
                <FaIcon
                  icon={faHelmetSafety}
                  color={
                    showSafetyEquipmentDisabled
                      ? undefined
                      : showSafetyEquipmentOk
                      ? theme.palette.success.main
                      : theme.palette.warning.main
                  }
                />
              </QcsButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
          {/* SHOW EMPLOYEE PIN */}
          <QcsTooltip
            title={t('invitation.tabs.tooltips.pinEmployee')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleShowPin(item);
                }}
                disabled={showEmployeePinDisabled}
              >
                <FaIcon
                  icon={faUserLock}
                  color={
                    showEmployeePinDisabled
                      ? undefined
                      : theme.palette.success.main
                  }
                />
              </QcsButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
          {/* DELETE EMPLOYEE */}
          {!isOneTime && (
            <QcsTooltip
              title={t('invitation.tabs.tooltips.deleteEmployee')}
              placement="top"
            >
              <InnerTooltipWrapper>
                <QcsButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeletePerson(item);
                  }}
                  disabled={deleteEmployeeDisabled}
                >
                  <DeleteForeverIcon
                    color={deleteEmployeeDisabled ? undefined : 'error'}
                  />
                </QcsButton>
              </InnerTooltipWrapper>
            </QcsTooltip>
          )}
        </QcsTableCell>
      </>
    );
  };

  const handleAddClick = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = async (data?: InvitedPersonsAddModalData) => {
    if (data) {
      const invitedPersonDto: InvitedPersonDto = {
        ...data.supplierEmployee,
        id: undefined,
        activities: data.activities,
        workplaces: data.workplaces,
        employeeId: data.supplierEmployee.id,
      };

      await invitationsApi.addPerson(invitation.id!, invitedPersonDto);
      await reloadInvitation();
    }

    setModalIsOpen(false);
  };

  const handleChangeChecked = (items: InvitedPersonDto[]) => {
    setCheckedItems(items);
  };

  const handleItemToSelectObject = (item: InvitedPersonDto) => item;

  const handleSelectObjectGetId = (item: InvitedPersonDto) => item.id!;

  const handleCheckIsDisabled = (item: InvitedPersonDto) => {
    const startTestDisabled =
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerWorkplace,
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.Receptionist,
        UserDtoRolesEnum.ExternalManager,
      ]) ||
      isSaving ||
      invitation.state === InvitationDtoStateEnum.Archived ||
      (item.trainings?.length ?? 0) < 1;
    return startTestDisabled;
  };

  const handleStartTest = async () => {
    await startTest(checkedItems);
  };

  const startTest = async (persons: InvitedPersonDto[]) => {
    setIsSaving(true);
    try {
      //Get all trainings from selected users:
      const allTrainings: InvitationTrainingResponse[] = [];
      for (const person of persons) {
        for (const item of person.trainings ?? []) {
          const existing = allTrainings.find(
            (x) => x.training?.id === item.training?.id
          );

          if (!existing) {
            allTrainings.push({ ...item });
            continue;
          }

          if (
            !existing.questionnaireCompleted ||
            !item.questionnaireCompleted
          ) {
            existing.questionnaireCompleted = undefined;
          } else if (
            item.questionnaireCompleted < existing.questionnaireCompleted
          ) {
            existing.questionnaireCompleted = item.questionnaireCompleted;
          }

          if (
            !existing.trainingCompleted ||
            !item.trainingCompleted ||
            !existing.questionnaire ||
            !item.questionnaire
          ) {
            existing.trainingCompleted = undefined;
          } else if (item.trainingCompleted < existing.trainingCompleted) {
            existing.trainingCompleted = item.trainingCompleted;
          }
        }
      }

      //Filter completed.
      const incompleteTrainings = allTrainings.filter(
        (x) => !invitationTrainingIsCompleted(x)
      );

      const allPersons = persons.map((x) => ({
        id: x.id!,
        name: getFirstLastNameObj(x),
      }));

      const trainings: TourniquetResponse = {
        invitationPerson: tourniquetGetInvitationPerson(),
        trainings:
          incompleteTrainings.length === 0 ? allTrainings : incompleteTrainings,
      };

      //No trainings - error.
      if ((trainings.trainings?.length ?? 0) === 0) {
        throw getCustomError('noTrainings');
      }

      await tourniquetLoadData(
        invitation!.id!,
        trainings.trainings![0].training!.id,
        companyCustomization!,
        identity.companyId,
        allPersons,
        dispatch
      );

      dispatch(
        tourniquetIdentityActions.success({
          allData: [],
          data: {
            invitation: {
              id: invitation.id!,
              name: invitation.name,
            },
          },
          allPersons,
        })
      );

      dispatch(tourniquetTrainingsActions.success(trainings));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
  };

  const oneTimeHeader = useMemo(() => {
    if (!isOneTime) {
      return null;
    }

    const establishments = _.union(
      (invitation.workplaces ?? []).map((x) =>
        getLangNameObj(i18n, x.establishment)
      )
    );

    return (
      <div style={{ marginBottom: '1rem' }}>
        <QcsTypography variant="subtitle1">
          <strong>{`${t('invitation.oneTimeEstablishments')}:`}</strong>{' '}
          {joinStrings2(establishments)}
        </QcsTypography>
        <QcsTypography variant="subtitle1">
          <strong>{`${t('invitation.oneTimeWorkplaces')}:`}</strong>{' '}
          {joinStrings2(
            invitation.workplaces?.map((x) => getLangNameObj(i18n, x.workplace))
          )}
        </QcsTypography>
        <QcsTypography variant="subtitle1">
          <strong>{`${t('invitation.oneTimeActivities')}:`}</strong>{' '}
          {joinStrings2(
            invitation.activities?.map((x) => getLangNameObj(i18n, x.activity))
          )}
        </QcsTypography>
      </div>
    );
  }, [i18n, invitation.activities, invitation.workplaces, isOneTime, t]);

  return (
    <>
      <InvitedPersonsAddModal
        supplierId={invitation.supplier?.id}
        initActivities={
          invitation.activities?.map((x) => ({
            id: x.activity!.id!,
            name: x.activity?.name,
            nameEn: x.activity?.nameEn,
          })) ?? []
        }
        initWorkplaces={
          invitation.workplaces?.map((x) => ({
            id: x.workplace!.id!,
            name: getWorkplaceNameObj(i18n, x.workplace, x.establishment),
          })) ?? []
        }
        open={modalIsOpen}
        onClose={handleModalClose}
        excludeIds={invitation.persons?.map((x) => x.employeeId!) ?? []}
      />
      <InvitedPersonsEditModal
        data={editedData}
        onSave={handleEditSave}
        onClose={handleEditClose}
      />
      <InvitedPersonsDocumentModal
        data={invitation.persons?.find((x) => x.id === documentDataId)}
        onClose={handleDocumentClose}
      />
      <InvitedPersonsOverride
        data={personOverride}
        onClose={handlePersonOverrideClose}
      />
      <InvitedPersonsPinModal
        invitedPerson={invitedPersonForPin}
        onClose={handlePinClose}
      />
      <InvitedPersonsDeleteModal
        personToDelete={personToDelete}
        onClose={handleCloseDeletePerson}
      />
      <InvitedPersonsSafetyEquipmentModal
        data={personSafetyEquipments}
        onClose={handleCloseSafetyEquipments}
      />

      {oneTimeHeader}

      {!isOneTime &&
        hasRole(identity.roles, [
          UserDtoRolesEnum.ExternalManager,
          UserDtoRolesEnum.ExternalWorker,
          UserDtoRolesEnum.ManagerWorkplace,
          UserDtoRolesEnum.ManagerOsah,
        ]) && (
          <SectionHeader
            title=" "
            addText="invitation.persons.add"
            handleAddClick={handleAddClick}
            addDisabled={invitation.state === InvitationDtoStateEnum.Archived}
          />
        )}
      <GridList<InvitedPersonDto, InvitedPersonDto>
        headers={[
          { captionStr: 'invitation.persons.name' },
          { captionStr: 'invitation.persons.lastName' },
          {
            captionStr: 'invitation.persons.document',
            hideOnMobile: true,
          },
          {},
        ]}
        data={invitation.persons ?? []}
        renderData={handleRenderData}
        search={false}
        hidePagination={true}
        checkbox={!isOneTime}
        checkedItems={checkedItems}
        changeChecked={handleChangeChecked}
        itemToSelectObject={handleItemToSelectObject}
        selectObjectGetId={handleSelectObjectGetId}
        isCheckDisabled={handleCheckIsDisabled}
      />

      {!isOneTime &&
        hasRole(identity.roles, [
          UserDtoRolesEnum.ManagerWorkplace,
          UserDtoRolesEnum.ManagerOsah,
          UserDtoRolesEnum.Receptionist,
          UserDtoRolesEnum.ExternalManager,
        ]) && (
          <QcsButton
            variant="contained"
            onClick={handleStartTest}
            disabled={
              checkedItems.length === 0 ||
              isSaving ||
              invitation.state === InvitationDtoStateEnum.Archived
            }
            sx={{ mt: '1.5rem' }}
          >
            {t('invitation.persons.startTest')}
          </QcsButton>
        )}
    </>
  );
};
