import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import { QcsLoadingButton } from './basic/QcsLoadingButton';
import { canvasToBlob } from 'blob-util';
import { Form, FormikProps } from 'formik';
import { FormikDebounce } from './form/FormikDebounce';
import { ReferenceDto } from '@qcs/safety-client';
import { FormContainer } from './form/FormContainer';
import { Select } from './form/Select';
import { SaveError } from './SaveError';
import { setErrorStateSnacks } from '../../utils/error';
import { SubmitButton } from './form/SubmitButton';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { QcsMenuItem } from './basic/QcsMenuItem';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { mediaApi } from '../../utils/api';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { useAppSelector } from '../../store';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { checkFileSize } from '../../utils/upload';
import { getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';

export const ControlButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

interface SingUserForm {
  userId?: string;
}

interface Props {
  playground?: boolean;
  userList: ReferenceDto[];
  save: (userId: string, documentId: string) => Promise<void>;
}

export const SignUser: FC<Props> = ({ playground, userList, save }) => {
  const { t, i18n } = useTranslation();
  const canvasRef = useRef<SignatureCanvas>(null);
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const [isEmpty, setIsEmpty] = useState(true);
  const [error, setError] = useState<ErrorStateType>();
  const theme = useTheme();
  const formikRef = useRef<FormikProps<SingUserForm> | null>(null);

  useEffect(() => {
    formikRef.current?.resetForm();
  }, [userList]);

  const handleClear = () => {
    canvasRef.current?.clear();
    setIsEmpty(true);
  };

  const handleSignEnd = () => {
    if (!isEmpty) {
      return;
    }

    if (!canvasRef.current) {
      return;
    }

    if (!canvasRef.current?.isEmpty()) {
      setIsEmpty(false);
    }
  };

  const handleSubmit = async (data: SingUserForm) => {
    setError('');
    try {
      //Set background to white (originally as transparent).
      //If you use "backgroundColor" property, then getTrimmedCanvas dont work.
      const canvas2 = canvasRef.current!.getTrimmedCanvas();
      const canvas3 = document.createElement('canvas');
      canvas3.width = canvas2.width;
      canvas3.height = canvas2.height;
      const ctx = canvas3.getContext('2d')!;
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas2.width, canvas2.height);
      ctx.drawImage(canvas2, 0, 0);

      const image = await canvasToBlob(canvas3, 'image/png');
      const file = new File([image], 'sign.png');

      let res;
      if (playground) {
        res = { data: { id: '' } };
      } else {
        checkFileSize(file);
        res = await mediaApi.uploadMedia(identityCompanyId, true, file);
      }
      await save(data.userId!, res.data.id!);

      enqueueSuccessSnackbar(
        t(playground ? 'tourniquet.signPlayground' : 'common.saveSuccess')
      );
      handleClear();
    } catch (err) {
      setErrorStateSnacks(err, setError, enqueueErrorSnackbar);
    }
  };

  return (
    <FormikDebounce<SingUserForm>
      initialValues={{ userId: userList[0]?.id }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      innerRef={formikRef}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormContainer>
            <Select
              name="userId"
              label={t('tourniquet.signer')}
              disabled={userList.length < 2}
            >
              {userList.map((x) => (
                <QcsMenuItem key={x.id} value={x.id}>
                  {getLangNameObj(i18n, x)}
                </QcsMenuItem>
              ))}
            </Select>

            <QcsBox
              sx={{
                border: `1px solid ${theme.palette.common.black}`,
                height: '260px',
                backgroundColor: theme.palette.common.white,
              }}
            >
              <SignatureCanvas
                ref={canvasRef}
                onEnd={handleSignEnd}
                canvasProps={{ style: { width: '100%', height: '100%' } }}
              />
            </QcsBox>

            <SaveError error={error} />

            <ControlButtonsContainer>
              <QcsLoadingButton
                variant="contained"
                onClick={handleClear}
                loading={isSubmitting}
                disabled={isEmpty}
              >
                {t('tourniquet.signAgain')}
              </QcsLoadingButton>
              <SubmitButton text="common.sign" disabled={isEmpty} />
            </ControlButtonsContainer>
          </FormContainer>
        </Form>
      )}
    </FormikDebounce>
  );
};
