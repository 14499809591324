import { FC, Suspense } from 'react';
import { Provider } from 'react-redux';

import { BrowserRouterWithHistory } from './components/common/BrowserRouterWithHistory';
import { Layout } from './components/layout/Layout';
import { Providers } from './components/layout/Providers';
import { Routes1 } from './Routes1';
import { store } from './store';
import { Loader } from './components/common/Loader';

export const App: FC = () => {
  return (
    <Suspense fallback={<Loader sx={{ color: '#ff7c43' }} />}>
      <Provider store={store}>
        <BrowserRouterWithHistory>
          <Providers>
            <Layout>
              <Routes1 />
            </Layout>
          </Providers>
        </BrowserRouterWithHistory>
      </Provider>
    </Suspense>
  );
};
