import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  EstablishmentDto,
  EstablishmentDtoStateEnum,
  ReferenceDto,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { establishmentApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<EstablishmentDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsEstablishmentSlice = baseSlice({
  name: 'optionsEstablishment',
  initialState,
});

export const selectOptionsEstablishmentState = (state: RootState) =>
  state.optionsEstablishment.state;
export const selectOptionsEstablishment = (state: RootState) =>
  state.optionsEstablishment.data.map(
    (x) => ({ id: x.id, name: x.name, nameEn: x.nameEn } as ReferenceDto)
  );
export const optionsEstablishmentActions = optionsEstablishmentSlice.actions;

export const getOptionsEstablishment =
  (
    companyId: string,
    mineOnly: boolean | undefined,
    search: string | undefined,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await establishmentApi.queryEstablishments(
        companyId,
        search,
        EstablishmentDtoStateEnum.Active,
        true,
        mineOnly,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsEstablishmentActions,
      getData: handleGetData,
    });
  };
