import {
  EstablishmentDto,
  EstablishmentDtoStateEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { establishmentApi } from '../../utils/api';
import { QcsTypography } from '../common/basic/QcsTypography';
import { ErrorAlert } from '../common/ErrorAlert';
import { EstablishmentAutocomplete } from '../common/form/EstablishmentAutocomplete';
import { Loader } from '../common/Loader';
import {
  DashboardContent,
  DashboardEstablishmentForm,
} from './DashboardContent';
import { DashboardContainer, DashboardHeader } from './DashboardStyles';

export const Dashboard: FC = () => {
  const { t } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [firstEstablishment, setFirstEstablishment] =
    useState<EstablishmentDto>();

  useEffect(() => {
    (async () => {
      try {
        const establishments = await establishmentApi.queryEstablishments(
          identityCompanyId,
          undefined, //search
          EstablishmentDtoStateEnum.Active,
          true,
          undefined, //mineOnly
          0,
          1,
          ['name', 'asc']
        );
        const establishment = establishments.data.content?.[0];

        setFirstEstablishment(establishment);
      } catch {
        setError(true);
      }
      setLoading(false);
    })();
  }, [dispatch, identityCompanyId]);

  const handleSubmit = () => {
    //Nothing.
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert />;
  }

  return (
    <FormikDebounce<DashboardEstablishmentForm>
      initialValues={{
        establishment: firstEstablishment
          ? {
              id: firstEstablishment.id!,
              name: firstEstablishment.name,
              nameEn: firstEstablishment.nameEn,
            }
          : undefined,
      }}
      onSubmit={handleSubmit}
    >
      <Form>
        <DashboardContainer>
          <DashboardHeader>
            <QcsTypography variant="h4">{t('dashboard.title')}</QcsTypography>
            <EstablishmentAutocomplete
              sx={{ minWidth: '18rem' }}
              name="establishment"
              label={t('dashboard.establishment')}
              required={true}
            />
          </DashboardHeader>
          <DashboardContent />
        </DashboardContainer>
      </Form>
    </FormikDebounce>
  );
};
