import { CreateUpdateInvitation, InvitationDto } from '@qcs/safety-client';
import { getFirstLastNameObj } from '../../../../utils/format';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  invitationActions,
  selectInvitation,
} from '../../../../store/entities/invitation';
import { useAppSnackbar } from '../../../../hooks/useAppSnackbar';
import { useState } from 'react';
import { ErrorStateType } from '../../../../models/common';
import { invitationListActions } from '../../../../store/entities/invitationList';
import { invitationHistoryListActions } from '../../../../store/entities/invitationHistoryList';
import { invitationsApi } from '../../../../utils/api';
import { invitationStateAudActions } from '../../../../store/entities/invitationStateAud';
import { invitationTypeVisitListActions } from '../../../../store/entities/invitationTypeVisitList';

export const invitationGetUpdateDto = (invitation: InvitationDto) => {
  const res: CreateUpdateInvitation = {
    activities:
      invitation.activities?.map((x) => ({
        id: x.activity?.id ?? '',
        name: x.activity?.name,
        nameEn: x.activity?.nameEn,
      })) ?? [],
    attachments: invitation.attachments?.map((x) => x.id ?? ''),
    employee: {
      id: invitation.employee?.id ?? '',
      name: getFirstLastNameObj(invitation.employee),
    },
    name: invitation.name ?? '',
    note: invitation.note,
    noteInternal: invitation.noteInternal,
    responsiblePerson: invitation.responsiblePerson!,
    supplier: invitation.supplier,
    validFrom: invitation.validFrom!,
    validTo: invitation.validTo,
    workplaces:
      invitation.workplaces?.map((x) => ({
        id: x.workplace?.id ?? '',
        name: x.workplace?.name,
        nameEn: x.workplace?.nameEn,
      })) ?? [],
  };
  return res;
};

export const invitationLock = {
  locked: false,
};

export const useInvitation = () => {
  const { t, i18n } = useTranslation();
  const invitation = useAppSelector(selectInvitation);
  const dispatch = useAppDispatch();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<ErrorStateType>();

  const lockInvitation = () => {
    invitationLock.locked = true;
  };

  const reloadInvitation = async () => {
    const dataResponse = await invitationsApi.getInvitationById(invitation.id!);

    try {
      const auditResponse = await invitationsApi.getInvitationAudit(
        invitation.id!,
        0,
        1
      );
      dispatch(
        invitationStateAudActions.success(auditResponse.data.content?.[0])
      );
    } catch (err) {
      dispatch(invitationStateAudActions.error());
    }

    dispatch(invitationActions.success(dataResponse.data));
    dispatch(invitationListActions.reload(true));
    dispatch(invitationHistoryListActions.reload(true));
    dispatch(invitationTypeVisitListActions.reload(true));
  };

  const unlockInvitation = () => {
    invitationLock.locked = false;
  };

  return {
    t,
    i18n,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    dispatch,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
    saveError,
    setSaveError,
  };
};
