import {
  TrainingDto,
  TrainingDtoStateEnum,
  TrainingVersionDto,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  trainingActions,
  getTraining,
  selectTraining,
  selectTrainingState,
} from '../../store/entities/training';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import { trainingsApi } from '../../utils/api';
import { trainingListActions } from '../../store/entities/trainingList';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { SaveError } from '../common/SaveError';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { DetailHeader } from '../common/DetailHeader';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { TrainingVersion } from './TrainingVersion';
import { WorkplaceAutocomplete } from '../common/form/WorkplaceAutocomplete';
import { FormGroup } from '../common/form/FormGroup';
import { QuestionnaireAutocomplete } from '../common/form/QuestionnaireAutocomplete';
import { DatePicker } from '../common/form/DatePicker';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { getDateForApi } from '../../utils/date';
import {
  getCompanyChecks,
  selectCompanyChecksCanCreateTraining,
  selectCompanyChecksState,
} from '../../store/entities/companyChecks';
import { setErrorStateSnacks } from '../../utils/error';
import {
  fixNameInitValue,
  getLangNameObj,
  joinStrings2,
} from '../../utils/format';
import { InputTextWithLang } from '../common/form/InputTextWithLang';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

type Validator = {
  name?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameEn?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameRu?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameDe?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  namePl?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  validFrom?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  duration?: Yup.NumberSchema<number, Yup.AnyObject, undefined, ''>;
};

type TrainingForm = Omit<TrainingDto, 'questionnaire'> &
  TrainingVersionDto & { versionNote?: string };

export const TrainingDetail: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/training');
  const { trainingId } = useParams();
  const training = useAppSelector(selectTraining);
  const trainingState = useAppSelector(selectTrainingState);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyChecksState = useAppSelector(selectCompanyChecksState);
  const companyChecksCanCreateTraining = useAppSelector(
    selectCompanyChecksCanCreateTraining
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  useEffect(() => {
    if (trainingId === 'new') {
      dispatch(trainingActions.default());
      dispatch(getCompanyChecks(identityCompanyId!));
    } else {
      dispatch(getTraining(trainingId!));
    }
    setLoading(false);
  }, [trainingId, identityCompanyId, dispatch]);

  const handleSubmit = async (data: TrainingForm) => {
    setSaveError('');

    //Fix BE validation on editing.
    const duration =
      trainingId === 'new' ? data.duration : data.duration ? data.duration : 1;

    const trainingData: TrainingDto = {
      name: data.name,
      nameEn: data.nameEn,
      state: data.state,
      note: data.note,
      duration,
    };

    const versionData: TrainingVersionDto = {
      validFrom: data.validFrom,
      validTo: data.validTo,
      questionnaire: data.questionnaire,
      duration,
      note: data.versionNote,
    };

    try {
      if (trainingId === 'new') {
        // Training creation causes creating a new blank version. Find it and update by data from the form.
        const trainingResponse = await trainingsApi.create(
          identityCompanyId,
          trainingData
        );
        const versionsResponse = await trainingsApi.versionsPerTraining(
          trainingResponse.data.id!
        );
        await trainingsApi.updateVersion(
          versionsResponse.data.content![0].id!,
          versionData
        );
        enqueueSuccessSnackbar(t('training.addSuccess'));
      } else if (trainingId) {
        await trainingsApi.update(trainingId, trainingData);
        enqueueSuccessSnackbar(t('training.updateSuccess'));
      }

      dispatch(trainingListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        trainingId === 'new' ? 'training.addError' : 'common.editError'
      );
    }
  };

  if (
    loading ||
    trainingState === FetchState.Loading ||
    (trainingId === 'new' && companyChecksState === FetchState.Loading)
  ) {
    return <Loader />;
  }

  if (
    trainingState === FetchState.Error ||
    (trainingId === 'new' && companyChecksState === FetchState.Error)
  ) {
    return <ErrorAlert />;
  }

  const validator: Validator = {};

  if (companyCustomization?.applicationLanguages.includes('cs')) {
    validator['name'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('en')) {
    validator['nameEn'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('de')) {
    validator['nameDe'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('pl')) {
    validator['namePl'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('ru')) {
    validator['nameRu'] = validations.stringRequired(t);
  }
  if (trainingId === 'new') {
    (validator['validFrom'] = validations.stringDateNotPastRequired(t)),
      (validator['duration'] = validations.intPositive(t));
  }

  return (
    <>
      <DetailHeader title="training.detailTitle" backTo={backTo} />
      <FormikDebounce<TrainingForm>
        initialValues={{
          ...training,
          ...fixNameInitValue(i18n, training),
          workplaces: training.workplaces?.map((workplace) => ({
            ...workplace,
            establishment: workplace.ref,
          })),
          questionnaire: training.questionnaire?.[0]
            ? {
                id: '',
                name: joinStrings2(
                  training.questionnaire!.map((x) => getLangNameObj(i18n, x))
                ),
              }
            : undefined,
          validFrom: getDateForApi(new Date())!,
        }}
        validationSchema={Yup.object().shape(validator)}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <InputTextWithLang
              isNew={trainingId === 'new'}
              label={t('training.name')}
              maxLength={50}
              required
            />
            <Select name="state" label={t('training.state')}>
              <QcsMenuItem value={TrainingDtoStateEnum.Active}>
                {t('entityState.ACTIVE')}
              </QcsMenuItem>
              <QcsMenuItem value={TrainingDtoStateEnum.Inactive}>
                {t('entityState.INACTIVE')}
              </QcsMenuItem>
            </Select>
            <Input
              name="note"
              label={t('training.note')}
              maxLength={250}
              multiline={true}
              rows={4}
            />
            {trainingId !== 'new' && (
              <WorkplaceAutocomplete
                multiple
                name="workplaces"
                label={t('training.workplaces')}
                disabled
                showEstabilishmentName={true}
              />
            )}
            {trainingId === 'new' && (
              <FormGroup name="training.firstVersion">
                <DatePicker
                  name="validFrom"
                  label={t('training.validFrom')}
                  disablePast
                  disabled
                  required
                />
                <DatePicker
                  name="validTo"
                  label={t('training.validTo')}
                  disabled
                />
                <QuestionnaireAutocomplete
                  name="questionnaire"
                  label={t('training.questionnaire')}
                />
                <Input
                  name="duration"
                  label={t('training.duration')}
                  type="number"
                  required
                />
                <Input
                  name="versionNote"
                  label={t('training.versionNote')}
                  maxLength={250}
                  multiline={true}
                  rows={4}
                />
              </FormGroup>
            )}
            <SaveError error={saveError} />
            <SubmitButton
              disabled={trainingId === 'new' && !companyChecksCanCreateTraining}
            />
          </FormContainer>
        </Form>
      </FormikDebounce>
      {trainingId !== 'new' && <TrainingVersion />}
    </>
  );
};
