import {
  FileUploadMediaResponse,
  InvitationActivityDto,
  InvitationDocumentResponse,
  InvitationDtoStateEnum,
  InvitedPersonDto,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { FC, useMemo, useRef, useState } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { GridList } from '../../../common/grid/GridList';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { CellDate } from '../../../common/grid/CellDate';
import {
  getFirstLastNameObj,
  getLangNameObj,
  invitationCertificateIsExpired,
} from '../../../../utils/format';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useAppSelector } from '../../../../store';
import { getMediaToken } from '../../../../utils/storage';
import {
  BASE_URL_WITH_VERSION,
  invitationsApi,
  mediaApi,
} from '../../../../utils/api';
import { InvitedPersonsDocumentDateModal } from './InvitedPersonsDocumentDateModal';
import { DocumentInputUploadModal } from '../../../risk/DocumentInputUploadModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  InnerTooltipWrapper,
  QcsTooltip,
} from '../../../common/basic/QcsTooltip';
import { selectIdentity } from '../../../../store/entities/identity';
import { hasRole } from '../../../../utils/roles';
import { setErrorSnacks } from '../../../../utils/error';
import { useNavigate } from 'react-router';
import { useInvitation } from './invitationFunctions';
import { useTheme } from '@mui/material';

interface ActivityCertificateModalForm {
  id?: string;
  document: InvitationDocumentResponse;
  person: InvitedPersonDto;
}

interface Props {
  item?: InvitationActivityDto;
  onClose: () => void;
}

export const ActivityCertificateModal: FC<Props> = ({ item, onClose }) => {
  const {
    t,
    i18n,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const [uploadModal, setUploadModal] =
    useState<ActivityCertificateModalForm>();
  const [dateModal, setDateModal] = useState(false);
  const [validTo, setValidTo] = useState<string>();
  const mediaToken = getMediaToken();
  const identity = useAppSelector(selectIdentity);
  const theme = useTheme();
  const navigate = useNavigate();

  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const list = useMemo(
    () =>
      ([] as ActivityCertificateModalForm[]).concat(
        ...(invitation.persons?.map((person) =>
          (
            person.documents?.filter((document) =>
              item?.activity?.certificates?.includes(
                document.certificate?.id ?? ''
              )
            ) ?? []
          ).map((document) => ({
            id: document.id,
            document,
            person,
          }))
        ) ?? [])
      ),
    [invitation.persons, item?.activity?.certificates]
  );

  const handleRenderData = (item: ActivityCertificateModalForm) => {
    const expired = !invitationCertificateIsExpired(item.document);
    const uploadDisabled =
      invitation.state === InvitationDtoStateEnum.Archived ||
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerWorkplace,
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.ExternalManager,
      ]);
    const downloadDisabled = !item.document.document;
    const approveDisabled =
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.ManagerWorkplace,
      ]) ||
      !item.document.document ||
      expired;
    const alreadyApproved = !!item.document.approvedOn;
    const deleteDisabled =
      !item.document.document ||
      invitation.state === InvitationDtoStateEnum.Archived;

    const handleRowClick = () => {
      if (
        hasRole(identity.roles, [
          UserDtoRolesEnum.AdminQcs,
          UserDtoRolesEnum.AdminCompany,
          UserDtoRolesEnum.ManagerOsah,
          UserDtoRolesEnum.ManagerWorkplace,
          UserDtoRolesEnum.Receptionist,
        ])
      ) {
        navigate(
          `/supplier/${invitation.supplier?.id}/employee/${item.person.employeeId}`,
          {
            state: { back: `/invitation/${invitation.id}/activity` },
          }
        );
      } else if (hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])) {
        navigate(`/supplier/employee/${item.person.employeeId}`, {
          state: { back: `/invitation/${invitation.id}/activity` },
        });
      }
    };

    return (
      <>
        <QcsTableCell onClick={handleRowClick}>
          {getFirstLastNameObj(item.person)}
        </QcsTableCell>
        <QcsTableCell>
          {getLangNameObj(i18n, item.document.certificate)}
        </QcsTableCell>
        <CellDate
          sx={{
            color: expired ? theme.palette.error.main : undefined,
          }}
          value={item.document.validTo}
        />
        <QcsTableCell align="right">
          <QcsTooltip
            title={`${t('invitation.tabs.tooltips.uploadCertificate')} ${t(
              'common.fileMaxSize'
            )}`}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                disabled={uploadDisabled}
                onClick={handleUpload(item)}
                loading={isSaving}
              >
                <CloudUploadIcon
                  color={
                    uploadDisabled
                      ? undefined
                      : !!item.document.document && !expired
                      ? 'success'
                      : 'warning'
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.downloadCertififace')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleDownload(item)}
                disabled={downloadDisabled}
                loading={isSaving}
              >
                <CloudDownloadIcon
                  color={downloadDisabled ? undefined : 'success'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.approveCertificate')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleApprove(item)}
                disabled={approveDisabled || alreadyApproved}
                loading={isSaving}
              >
                <CheckCircleIcon
                  color={
                    approveDisabled
                      ? undefined
                      : alreadyApproved
                      ? 'success'
                      : 'warning'
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.deleteCertificate')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleDelete(item)}
                disabled={deleteDisabled}
                loading={isSaving}
              >
                <DeleteForeverIcon
                  color={deleteDisabled ? undefined : 'error'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
        </QcsTableCell>
      </>
    );
  };

  const handleUpload = (item: ActivityCertificateModalForm) => () => {
    setUploadModal(item);

    if (item.document.certificate?.expiryDateRequired) {
      setDateModal(true);
    } else {
      setValidTo(undefined);
      fileRef.current?.click();
    }
  };

  const handleDateModalClose = (date?: string) => {
    setValidTo(date);
    setDateModal(false);

    if (date) {
      fileRef.current?.click();
    }
  };

  const handleFileChange = (files: FileList | null) => {
    if (!fileRef.current || !files) {
      return;
    }

    const file = files[0];
    setSelectedFile(file);
    fileRef.current.value = '';
  };

  const handleFileUploaded = async (document: FileUploadMediaResponse) => {
    if (!selectedFile || !uploadModal) {
      return;
    }

    lockInvitation();
    try {
      await invitationsApi.updateDocument(
        invitation.id!,
        uploadModal.document.id!,
        {
          document: document.id!,
          validTo,
        }
      );
      await reloadInvitation();
    } finally {
      unlockInvitation();
    }

    setSelectedFile(null);
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
  };

  const handleDownload = (item: ActivityCertificateModalForm) => () => {
    const url = `${BASE_URL_WITH_VERSION}${item.document?.document?.path}?token=${mediaToken}`;
    const link = document.createElement('a');
    link.href = url;
    // Taget for no "same-origin" - some browser can block download and redirect to file.
    link.target = '_blank';
    link.download = item.document!.document!.fileName!;
    link.click();
  };

  const handleApprove = (item: ActivityCertificateModalForm) => async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await invitationsApi.approveDocumentCertificate(invitation.id!, {
        entries: [item.document!.document!.id!],
      });
      await reloadInvitation();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  const handleDelete = (item: ActivityCertificateModalForm) => async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await mediaApi.unpairDocumentFromEntity(
        item.document.id!,
        'FROM_INVITATION'
      );
      await reloadInvitation();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  return (
    <>
      <InvitedPersonsDocumentDateModal
        open={dateModal}
        onClose={handleDateModalClose}
      />
      <DocumentInputUploadModal
        file={selectedFile}
        onFileUploaded={handleFileUploaded}
        onCloseOnError={handleFileCancel}
      />
      <input
        type="file"
        ref={fileRef}
        onChange={({ target }) => handleFileChange(target.files)}
        hidden
      />

      <QcsModal open={!!item} onClose={onClose}>
        <QcsModalBox>
          <SectionHeader title="invitation.activity.certificateTitle" />
          <GridList<ActivityCertificateModalForm>
            headers={[
              { captionStr: 'invitation.activity.certificatePerson' },
              { captionStr: 'invitation.activity.certificateName' },
              { captionStr: 'invitation.activity.certificateValidTo' },
              {},
            ]}
            data={list}
            renderData={handleRenderData}
            search={false}
            hidePagination={true}
          />
        </QcsModalBox>
      </QcsModal>
    </>
  );
};
