import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  InvitationDtoTypeEnum,
  ReferenceDto,
  VisitationDto,
  WorkplaceDto,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { visitationApi } from '../../utils/api';
import { DateFromTo } from '../../models/common';
import { getDateTimeForApi } from '../../utils/date';

export interface VisitationListFilter extends ListFilter {
  supplier?: ReferenceDto;
  establishment?: ReferenceDto;
  workplace?: WorkplaceDto;
  invitedBy?: ReferenceDto;
  entryTime?: DateFromTo;
  exitTime?: DateFromTo;
  hasExited?: boolean;
  invitationTypes?: InvitationDtoTypeEnum[];
}

export type VisitationListState = ListType<VisitationDto, VisitationListFilter>;

const initialState: VisitationListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'exitTime',
    sortDesc: true,
    hasExited: true,
  },
};

export const visitationListSlice = listSlice({
  name: 'visitationList',
  initialState,
});

export const selectVisitationList = (state: RootState) => state.visitationList;
export const visitationListActions = visitationListSlice.actions;

export const getVisitationList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectVisitationList(storeState);

    const handleGetData = (size: number) => {
      return visitationApi.queryVisitations(
        companyId,
        data.query.q,
        undefined, //nameOnly
        data.query.supplier?.id,
        data.query.establishment?.id,
        data.query.workplace ? [data.query.workplace.id!] : undefined,
        data.query.invitedBy?.id,
        getDateTimeForApi(data.query.entryTime?.from),
        getDateTimeForApi(data.query.entryTime?.to),
        getDateTimeForApi(data.query.exitTime?.from),
        getDateTimeForApi(data.query.exitTime?.to),
        data.query.hasExited,
        data.query.invitationTypes,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: visitationListActions,
      getData: handleGetData,
    });
  };
