import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as  QcsLink } from '@mui/material';

import { QcsTypography } from '../common/basic/QcsTypography';
import { FooterContainer } from './LayoutStyles';
import Logo from '../../images/logo.svg';
import { QC_SOLUTIONS_URL } from '../../utils/constants';
import { format, parseISO } from 'date-fns';
import {
  getVersion,
  selectVersion,
  selectVersionState,
} from '../../store/entities/version';
import { useAppDispatch, useAppSelector } from '../../store';

const RELEASE_COMMIT = import.meta.env.VITE_RELEASE_COMMIT as string;
const RELEASE_VERSION = import.meta.env.VITE_RELEASE_VERSION as string;

export const Footer: FC = () => {
  const { t } = useTranslation();
  const versionState = useAppSelector(selectVersionState);
  const version = useAppSelector(selectVersion);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getVersion());
  }, [versionState, dispatch]);

  const versionString = useMemo(() => {
    const dateVersion = parseISO(RELEASE_VERSION);
    const dateStr = format(dateVersion, 'yyyyMMdd');

    return [dateStr, RELEASE_COMMIT.substring(0, 7), version.git?.commit?.id]
      .filter((x) => !!x)
      .join(' - ');
  }, [version.git?.commit?.id]);

  return (
    <FooterContainer>
      <img
        src={Logo}
        alt="QCS"
        height={18}
        style={{ marginRight: '0.75rem' }}
      />
      <QcsTypography fontSize="12px" sx={{ marginLeft: '0.25rem' }}>
        {t('footer.app')}{' '}
        <QcsLink
          href={QC_SOLUTIONS_URL}
          target="_blank"
          rel="noreferrer noopener"
        >
          QC Solutions
        </QcsLink>
        . All rights reserved. {versionString}
      </QcsTypography>
    </FooterContainer>
  );
};
