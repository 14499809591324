import {
  InvitationDto,
  InvitationDtoTypeEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  invitationListActions,
  getInvitationList,
  selectInvitationList,
} from '../../store/entities/invitationList';
import {
  selectIdentity,
  selectIdentityCompanyId,
} from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { CellDate } from '../common/grid/CellDate';
import { InvitationFilter } from './InvitationFilter';
import { hasRole, isExternal } from '../../utils/roles';
import { getLangNameObj } from '../../utils/format';

export const Invitation: FC = () => {
  const { t, i18n } = useTranslation();
  const identity = useAppSelector(selectIdentity);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const invitationList = useAppSelector(selectInvitationList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getInvitationList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: InvitationDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell>{getLangNameObj(i18n, item.supplier)}</QcsTableCell>
        <CellDate value={item.validFrom} hideOnMobile={true} />
        <CellDate value={item.validTo} hideOnMobile={true} />
        <QcsTableCell hideOnMobile={true}>
          {t(
            'invitation.invitationTrainingStates.' +
              item.invitationTrainingState
          )}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('invitation.states.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: InvitationDto) => {
    navigate(
      `/invitation/${item.id}/${
        item.type === InvitationDtoTypeEnum.OneTime ||
        item.type === InvitationDtoTypeEnum.OneTimeEntry ||
        hasRole(identity.roles, [UserDtoRolesEnum.ExternalWorker])
          ? 'person'
          : 'detail'
      }`
    );
  };

  const handleAddClick = () => {
    navigate('/invitation/new');
  };

  return (
    <>
      <SectionHeader
        title="invitation.title"
        addText="invitation.add"
        handleAddClick={isExternal(identity) ? undefined : handleAddClick}
      />
      <Grid<InvitationDto>
        headers={[
          { captionStr: 'invitation.name', orderName: 'name' },
          { captionStr: 'invitation.supplier' },
          {
            captionStr: 'invitation.validFrom',
            orderName: 'valid_from',
            hideOnMobile: true,
          },
          {
            captionStr: 'invitation.validTo',
            orderName: 'valid_to',
            hideOnMobile: true,
          },
          {
            captionStr: 'invitation.invitationTrainingState',
            orderName: 'invitationTrainingState',
            hideOnMobile: true,
          },
          {
            captionStr: 'invitation.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={invitationList}
        gridActions={invitationListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        search={false}
        filter={<InvitationFilter query={invitationList.query} />}
      />
    </>
  );
};
