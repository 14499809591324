import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  EstablishmentDto,
  EstablishmentDtoStateEnum,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { establishmentApi } from '../../utils/api';

export interface EstablishmentListFilter extends ListFilter {
  state?: EstablishmentDtoStateEnum;
}

export type EstablishmentListState = ListType<
  EstablishmentDto,
  EstablishmentListFilter
>;

const initialState: EstablishmentListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: EstablishmentDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const establishmentListSlice = listSlice({
  name: 'establishmentList',
  initialState,
});

export const selectEstablishmentList = (state: RootState) =>
  state.establishmentList;
export const establishmentListActions = establishmentListSlice.actions;

export const getEstablishmentList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectEstablishmentList(storeState);

    const handleGetData = (size: number) => {
      return establishmentApi.queryEstablishments(
        companyId,
        data.query.q,
        data.query.state,
        undefined, //nameOnly
        undefined, //mineOnly
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: establishmentListActions,
      getData: handleGetData,
    });
  };
