import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsLoadingButton } from '../basic/QcsLoadingButton';

export const StyledLoadingButton = styled(QcsLoadingButton)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'fit-content',
    marginLeft: 'auto',
  },
}));

interface Props {
  className?: string;
  disabled?: boolean;
  text?: string | null;
}

export const SubmitButton: FC<Props> = ({ className, disabled, text }) => {
  const { isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  return (
    <StyledLoadingButton
      className={className}
      variant="contained"
      type="submit"
      disabled={disabled || isSubmitting}
      loading={isSubmitting}
    >
      {t(text ?? 'common.save')}
    </StyledLoadingButton>
  );
};
