import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { activitySlice } from './entities/activity';
import { activityListSlice } from './entities/activityList';
import { appTrainingLangListSlice } from './entities/appTrainingLangList';
import { documentSlice } from './entities/document';
import { documentListSlice } from './entities/documentList';
import { versionSlice } from './entities/version';
import { identitySlice } from './entities/identity';
import { safetyEquipmentSlice } from './entities/safetyEquipment';
import { safetyEquipmentListSlice } from './entities/safetyEquipmentList';
import { qcsAdminCompanySlice } from './entities/qcsAdminCompany';
import { qcsAdminCompanyListSlice } from './entities/qcsAdminCompanyList';
import { userSlice } from './entities/user';
import { qcsAdminUserListSlice } from './entities/qcsAdminUserList';
import { establishmentListSlice } from './entities/establishmentList';
import { establishmentSlice } from './entities/establishment';
import { establishmentLatestSlice } from './entities/establishmentLatest';
import { userListSlice } from './entities/userList';
import { companyPagesSlice } from './entities/companyPages';
import { employeeListSlice } from './entities/employeeList';
import { employeeSlice } from './entities/employee';
import { businessSettingsSlice } from './entities/bussinesSettings';
import { companySettingsSlice } from './entities/companySettings';
import { companyCustomizationSlice } from './entities/companyCustomization';
import { subdomainCustomizationSlice } from './entities/subdomainCustomization';
import { companyChecksSlice } from './entities/companyChecks';
import { riskListSlice } from './entities/riskList';
import { riskVersionListSlice } from './entities/riskVersionList';
import { riskSlice } from './entities/risk';
import { riskVersionSlice } from './entities/riskVersion';
import { supplierListSlice } from './entities/supplierList';
import { supplierSlice } from './entities/supplier';
import { supplierEmployeeListSlice } from './entities/supplierEmployeeList';
import { supplierEmployeeSlice } from './entities/supplierEmployee';
import { workplaceListSlice } from './entities/workplaceList';
import { workplaceSlice } from './entities/workplace';
import { optionsEstablishmentSlice } from './entities/optionsEstablishment';
import { optionsSafetyEquipmentSlice } from './entities/optionsSafetyEquipment';
import { optionsRiskSlice } from './entities/optionsRisk';
import { workplaceTrainingsSlice } from './entities/workplaceTrainings';
import { optionsTrainingSlice } from './entities/optionsTraining';
import { supplierEmployeeDocumentListSlice } from './entities/supplierEmployeeDocumentList';
import { trainingListSlice } from './entities/trainingList';
import { trainingSlice } from './entities/training';
import { trainingVersionListSlice } from './entities/trainingVersionList';
import { trainingVersionSlice } from './entities/trainingVersion';
import { optionsQuestionnaireSlice } from './entities/optionsQuestionnaire';
import { optionsWorkplaceSlice } from './entities/optionsWorkplace';
import { trainingSlidesSlice } from './entities/trainingSlides';
import { questionnaireListSlice } from './entities/questionnaireList';
import { questionnaireSlice } from './entities/questionnaire';
import { invitationListSlice } from './entities/invitationList';
import { invitationSlice } from './entities/invitation';
import { invitationHistoryListSlice } from './entities/invitationHistoryList';
import { invitationStateAudSlice } from './entities/invitationStateAud';
import { invitationTypeVisitListSlice } from './entities/invitationTypeVisitList';
import { optionsInvitationSlice } from './entities/optionsInvitation';
import { optionsEmployeeSlice } from './entities/optionsEmployee';
import { optionsActivitySlice } from './entities/optionsActivity';
import { optionsSupplierSlice } from './entities/optionsSupplier';
import { optionsSupplierEmployeeSlice } from './entities/optionsSupplierEmployee';
import { questionnaireVersionListSlice } from './entities/questionnaireVersionList';
import { questionnaireVersionSlice } from './entities/questionnaireVersion';
import { questionnaireQuestionsSlice } from './entities/questionnaireQuestions';
import { emergencyListSlice } from './entities/emergencyList';
import { emergencySlice } from './entities/emergency';
import { emergencyActiveSlice } from './entities/emergencyActive';
import { visitationListSlice } from './entities/visitationList';
import { visitationSlice } from './entities/visitation';
import { tourniquetIdentitySlice } from './entities/tourniquetIdentity';
import { tourniquetTrainingsSlice } from './entities/tourniquetTrainings';
import { tourniquetTrainingSlice } from './entities/tourniquetTraining';
import { tourniquetTrainingSlidesSlice } from './entities/tourniquetTrainingSlides';
import { tourniquetQuestionnaireSlice } from './entities/tourniquetQuestionnaire';
import { dashboardSlice } from './entities/dashboard';
import { trainingExternalListSlice } from './entities/trainingExternalList';
import { optionsInvitationTypeVisitSlice } from './entities/optionsInvitationTypeVisit';

export const store = configureStore({
  reducer: {
    version: versionSlice.reducer,
    identity: identitySlice.reducer,
    activityList: activityListSlice.reducer,
    activity: activitySlice.reducer,
    riskList: riskListSlice.reducer,
    risk: riskSlice.reducer,
    riskVersionList: riskVersionListSlice.reducer,
    riskVersion: riskVersionSlice.reducer,
    documentList: documentListSlice.reducer,
    document: documentSlice.reducer,
    userList: userListSlice.reducer,
    user: userSlice.reducer,
    employeeList: employeeListSlice.reducer,
    employee: employeeSlice.reducer,
    qcsAdminCompanyList: qcsAdminCompanyListSlice.reducer,
    qcsAdminCompany: qcsAdminCompanySlice.reducer,
    qcsAdminUserList: qcsAdminUserListSlice.reducer,
    businessSettings: businessSettingsSlice.reducer,
    companySettings: companySettingsSlice.reducer,
    companyCustomization: companyCustomizationSlice.reducer,
    subdomainCustomization: subdomainCustomizationSlice.reducer,
    companyChecks: companyChecksSlice.reducer,
    companyPages: companyPagesSlice.reducer,
    safetyEquipmentList: safetyEquipmentListSlice.reducer,
    safetyEquipment: safetyEquipmentSlice.reducer,
    appTrainingLangList: appTrainingLangListSlice.reducer,
    establishmentList: establishmentListSlice.reducer,
    establishment: establishmentSlice.reducer,
    establishmentLatest: establishmentLatestSlice.reducer,
    supplierList: supplierListSlice.reducer,
    supplier: supplierSlice.reducer,
    supplierEmployeeList: supplierEmployeeListSlice.reducer,
    supplierEmployee: supplierEmployeeSlice.reducer,
    supplierEmployeeDocumentList: supplierEmployeeDocumentListSlice.reducer,
    workplaceList: workplaceListSlice.reducer,
    workplace: workplaceSlice.reducer,
    workplaceTrainings: workplaceTrainingsSlice.reducer,
    trainingList: trainingListSlice.reducer,
    training: trainingSlice.reducer,
    trainingVersionList: trainingVersionListSlice.reducer,
    trainingVersion: trainingVersionSlice.reducer,
    trainingSlides: trainingSlidesSlice.reducer,
    questionnaireList: questionnaireListSlice.reducer,
    questionnaire: questionnaireSlice.reducer,
    questionnaireVersionList: questionnaireVersionListSlice.reducer,
    questionnaireVersion: questionnaireVersionSlice.reducer,
    questionnaireQuestions: questionnaireQuestionsSlice.reducer,
    invitationList: invitationListSlice.reducer,
    invitation: invitationSlice.reducer,
    invitationHistoryList: invitationHistoryListSlice.reducer,
    invitationStateAud: invitationStateAudSlice.reducer,
    invitationTypeVisitList: invitationTypeVisitListSlice.reducer,
    optionsInvitation: optionsInvitationSlice.reducer,
    optionsEstablishment: optionsEstablishmentSlice.reducer,
    optionsSafetyEquipment: optionsSafetyEquipmentSlice.reducer,
    optionsRisk: optionsRiskSlice.reducer,
    optionsTraining: optionsTrainingSlice.reducer,
    optionsQuestionnaire: optionsQuestionnaireSlice.reducer,
    optionsWorkplace: optionsWorkplaceSlice.reducer,
    optionsEmployee: optionsEmployeeSlice.reducer,
    optionsActivity: optionsActivitySlice.reducer,
    optionsSupplier: optionsSupplierSlice.reducer,
    optionsSupplierEmployee: optionsSupplierEmployeeSlice.reducer,
    optionsInvitationTypeVisit: optionsInvitationTypeVisitSlice.reducer,
    emergencyList: emergencyListSlice.reducer,
    emergency: emergencySlice.reducer,
    emergencyActive: emergencyActiveSlice.reducer,
    visitationList: visitationListSlice.reducer,
    visitation: visitationSlice.reducer,
    tourniquetIdentity: tourniquetIdentitySlice.reducer,
    tourniquetTrainings: tourniquetTrainingsSlice.reducer,
    tourniquetTraining: tourniquetTrainingSlice.reducer,
    tourniquetTrainingSlides: tourniquetTrainingSlidesSlice.reducer,
    tourniquetQuestionnaire: tourniquetQuestionnaireSlice.reducer,
    dashboard: dashboardSlice.reducer,
    trainingExternalList: trainingExternalListSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
