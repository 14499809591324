import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getOptionsEstablishment,
  selectOptionsEstablishment,
  selectOptionsEstablishmentState,
} from '../../../store/entities/optionsEstablishment';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import { ReferenceDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { SxProps, Theme } from '@mui/material';
import { getLangNameObj } from '../../../utils/format';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { PreSelectSingle } from './PreselectSingle';

interface Props {
  sx?: SxProps<Theme>;
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  mineOnly?: boolean;
}

export const EstablishmentBasic: FC<Props> = ({
  name,
  label,
  required,
  mineOnly,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsEstablishment = useAppSelector(selectOptionsEstablishment);
  const optionsEstablishmentState = useAppSelector(
    selectOptionsEstablishmentState
  );

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(
      getOptionsEstablishment(identityCompanyId, mineOnly, search, cancelToken)
    );
  };

  useEffect(() => {
    loadOptions(
      undefined,
      new axios.CancelToken((c) => console.log('Cancel token:', c))
    );
  }, []);
  return (
    <PreSelectSingle
      name={name}
      label={label}
      required={required}
      options={optionsEstablishment}
      loading={optionsEstablishmentState === FetchState.Loading}
      getOptionId={(option: ReferenceDto) => option.id!}
      getOptionName={(option: ReferenceDto) => getLangNameObj(i18n, option)}
      getOptionNameFromId={(opt) => opt}
    />
  );
};
