import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { SubmitButton } from '../common/form/SubmitButton';
import { SectionHeader } from '../common/SectionHeader';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';
import { validations } from '../../utils/validations';
import { EmployeeAutocomplete } from '../common/form/EmployeeAutocomplete';
import { InvitationTypeVisitRequest, ReferenceDto } from '@qcs/safety-client';
import { EstablishmentAutocomplete } from '../common/form/EstablishmentAutocomplete';
import { WorkplaceAutocomplete } from '../common/form/WorkplaceAutocomplete';
import { InvitationWorkplaceDto } from '../../models/invitation';
import { ActivityAutocomplete } from '../common/form/ActivityAutocomplete';
import { PhoneInput } from '../common/form/PhoneInput';
import { companyApi, invitationTypeVisitsApi } from '../../utils/api';
import {
  establishmentLatestActions,
  getEstablishmentLatest,
  selectEstablishmentLatest,
  selectEstablishmentLatestState,
} from '../../store/entities/establishmentLatest';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { invitationListActions } from '../../store/entities/invitationList';
import { invitationTypeVisitListActions } from '../../store/entities/invitationTypeVisitList';
import { visitationListActions } from '../../store/entities/visitationList';
import {
  VisitationAddModalOneTimeConflict,
  VisitationAddOneTimeConflictData,
  VisitationAddOneTimeConflictResult,
} from './VisitationAddModalOneTimeConflict';
import { useNavigate } from 'react-router';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

interface VisitationAddOneTimeForm
  extends Omit<
    InvitationTypeVisitRequest,
    | 'activities'
    | 'workplaces'
    | 'supplierEmployeeId'
    | 'supplierId'
    | 'visitingPerson'
  > {
  activities: Array<ReferenceDto>;
  workplaces: Array<InvitationWorkplaceDto>;
  establishments: Array<ReferenceDto>;
  employee?: ReferenceDto;
}

interface Props {
  open: boolean;
}

export const VisitationAddModalOneTime: FC<Props> = ({ open }) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const establishmentLatest = useAppSelector(selectEstablishmentLatest);
  const establishmentLatestState = useAppSelector(
    selectEstablishmentLatestState
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [dataConflict, setDataConflict] =
    useState<VisitationAddOneTimeConflictData>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getEstablishmentLatest(identity.companyId));
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSaveError('');
    setDataConflict(undefined);
  }, [open]);

  const handleSubmit = async (data: VisitationAddOneTimeForm) => {
    setSaveError('');
    try {
      const formData: InvitationTypeVisitRequest = {
        activities: data.activities.map((x) => x.id),
        company: data.company ? data.company : undefined,
        email: data.email ? data.email : undefined,
        language: data.language,
        lastName: data.lastName,
        name: data.name,
        phone: data.phone ? data.phone : undefined,
        supplierEmployeeId: undefined,
        supplierId: undefined,
        visitingPerson: data.employee!.id,
        workplaces: data.workplaces.map((x) => x.id),
      };

      if (formData.email) {
        const emailRes = await companyApi.findSupplierEmployeeInCompany(
          identity.companyId,
          formData.email
        );

        const dbData = emailRes.data;
        if (dbData) {
          if (dbData.supplier && !dbData.supplier.name) {
            dbData.supplier.name = undefined;
          }

          if (!dbData.phone) {
            dbData.phone = undefined;
          }

          formData.supplierId = dbData.supplier?.id;
          formData.supplierEmployeeId = dbData.id;
          formData.email = dbData.email;

          if (!dbData.oneTime) {
            formData.company = dbData.supplier?.name;
          }

          const isSame =
            formData.name === dbData.name &&
            formData.lastName === dbData.lastName &&
            formData.company === dbData.supplier?.name &&
            formData.phone === dbData.phone;

          if (!isSame) {
            setDataConflict({ form: formData, db: dbData });
            return;
          }
        }
      }

      await handleSave(formData);
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
  };

  const handleSave = async (data: InvitationTypeVisitRequest) => {
    const res = await invitationTypeVisitsApi.createInvitationTypeVisit(
      identity.companyId,
      data
    );

    enqueueSuccessSnackbar(t('visitation.oneTimeSuccess'));

    dispatch(visitationListActions.reload(true));
    dispatch(invitationListActions.reload(true));
    dispatch(invitationTypeVisitListActions.reload(true));
    dispatch(establishmentLatestActions.reset());

    navigate('/visitation/visit/' + res.data.id);
  };

  const handleConflict = async (result: VisitationAddOneTimeConflictResult) => {
    const form = dataConflict!.form;
    const db = dataConflict!.db;

    if (result === VisitationAddOneTimeConflictResult.UseDb) {
      form.name = db.name;
      form.lastName = db.lastName;
      form.company = db.supplier?.name;
      form.phone = db.phone;
    }

    if (
      result === VisitationAddOneTimeConflictResult.UseDb ||
      result === VisitationAddOneTimeConflictResult.UseForm
    ) {
      await handleSave(form);
    }

    setDataConflict(undefined);
  };

  if (loading || establishmentLatestState === FetchState.Loading) {
    return <Loader />;
  }

  if (establishmentLatestState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <VisitationAddModalOneTimeConflict
        data={dataConflict}
        onClose={handleConflict}
      />

      <SectionHeader title="visitation.oneTime" />
      <FormikDebounce<VisitationAddOneTimeForm>
        initialValues={{
          name: '',
          lastName: '',
          company: '',
          employee: undefined,
          establishments: establishmentLatest,
          workplaces: [],
          activities: [],
          phone: '',
          email: '',
          language: companyCustomization!.defaultLanguage,
        }}
        validationSchema={Yup.object({
          name: validations.stringRequired(t),
          lastName: validations.stringRequired(t),
          employee: validations.objectRequired(t),
          establishments: validations.arrayNotEmpty(t),
          workplaces: validations.arrayNotEmpty(t),
          activities: validations.arrayNotEmpty(t),
          phone: validations.phoneOptional(t),
          email: validations.emailOptional(t),
          language: validations.stringRequired(t),
        })}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormContainer>
              <Input
                name="name"
                label={t('visitation.oneTimeName')}
                required={true}
              />
              <Input
                name="lastName"
                label={t('visitation.oneTimeLastName')}
                required={true}
              />
              <Input name="company" label={t('visitation.oneTimeCompany')} />
              <EmployeeAutocomplete
                name="employee"
                label={t('visitation.oneTimeEmployee')}
                required={true}
                forInvite={true}
              />
              <EstablishmentAutocomplete
                name="establishments"
                label={t('visitation.oneTimeEstablishments')}
                multiple={true}
                required={true}
                mineOnly={true}
              />
              <WorkplaceAutocomplete
                name="workplaces"
                label={t('visitation.oneTimeWorkplaces')}
                multiple={true}
                required={true}
                estabilishments={values.establishments?.map((x) => x.id)}
                showEstabilishmentName={true}
              />
              <ActivityAutocomplete
                name="activities"
                label={t('visitation.oneTimeActivities')}
                multiple={true}
                required={true}
                oneTimeEntry={true}
              />
              <PhoneInput name="phone" label={t('visitation.oneTimePhone')} />
              <Input
                name="email"
                label={t('visitation.oneTimeEmail')}
                maxLength={100}
              />
              <LanguageSelect
                name="language"
                label={t('visitation.oneTimeLanguage')}
                languages={SUPPORTED_LANGUAGES}
                allowedLanguages={companyCustomization?.applicationLanguages}
                required
              />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </>
  );
};
