import ArrowForward from '@mui/icons-material/ArrowForward';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectTourniquetIdentity } from '../../store/entities/tourniquetIdentity';
import { selectTourniquetQuestionnaire } from '../../store/entities/tourniquetQuestionnaire';
import {
  selectTourniquetTraining,
  tourniquetTrainingActions,
} from '../../store/entities/tourniquetTraining';
import {
  selectTourniquetTrainings,
  tourniquetTrainingsActions,
} from '../../store/entities/tourniquetTrainings';
import { selectTourniquetTrainingSlides } from '../../store/entities/tourniquetTrainingSlides';
import { invitationsApi } from '../../utils/api';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { HtmlViewer } from '../common/html/HtmlViewer';
import { SignUser } from '../common/SignUser';
import { TourniquetButtonContent } from './TourniquetButtonContent';
import { TourniquetCaption } from './TourniquetCaption';
import { setErrorSnacks } from '../../utils/error';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { tourniquetLoadData } from './tourniquetFunctions';
import { TourniquetSlideCard } from './TourniquetStyles';

interface Props {
  onEndTraining: (success?: boolean) => void;
}

export const TourniquetTrainingSign: FC<Props> = ({ onEndTraining }) => {
  const { t } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const tourniquetTraining = useAppSelector(selectTourniquetTraining);
  const tourniquetTrainingSlides = useAppSelector(
    selectTourniquetTrainingSlides
  );
  const tourniquetQuestionnaire = useAppSelector(selectTourniquetQuestionnaire);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueErrorSnackbar } = useAppSnackbar();

  const hasQuestionnaire = tourniquetQuestionnaire.questions.length !== 0;
  const allSigned = tourniquetTraining.signQuestionnaire.length === 0;

  const handlePrev = () => {
    dispatch(
      tourniquetTrainingActions.success({
        ...tourniquetTraining,
        mode: 'training',
        index: tourniquetTrainingSlides.slides.length - 1,
      })
    );
  };

  const handleNext = async () => {
    if (tourniquetQuestionnaire.questions.length === 0) {
      //No questionnaire, end training.
      if ((tourniquetTrainings.trainings?.length ?? 0) < 2) {
        onEndTraining(true);
        return;
      }

      setLoading(true);
      try {
        const newTrainings = tourniquetTrainings.trainings!.filter(
          (x) => x.training?.id !== tourniquetTraining.training.id
        );

        dispatch(
          tourniquetTrainingsActions.success({
            ...tourniquetTrainings,
            trainings: newTrainings,
          })
        );

        await tourniquetLoadData(
          tourniquetIdentity.data!.invitation!.id!,
          newTrainings[0].training!.id!,
          companyCustomization!,
          identityCompanyId,
          tourniquetIdentity.allPersons!,
          dispatch
        );
      } catch (err) {
        setErrorSnacks(err, enqueueErrorSnackbar);
      }
      setLoading(false);
    } else {
      //Switch to questionnaire.
      dispatch(
        tourniquetTrainingActions.success({
          ...tourniquetTraining,
          index: 0,
          mode: 'questionnaire',

          answerResponse: undefined,
        })
      );
    }
  };

  const handleSignSave = async (userId: string, documentId: string) => {
    if (!tourniquetIdentity.playground) {
      await invitationsApi.signQuestionnaire(
        tourniquetIdentity.data!.invitation!.id!,
        {
          person: userId,
          signatureDocumentId: documentId,
          training: tourniquetTraining.training.id,
          trainingLanguageVariant: tourniquetTrainingSlides.language!.code,
        }
      );
    }

    dispatch(
      tourniquetTrainingActions.success({
        ...tourniquetTraining,
        signQuestionnaire: tourniquetTraining.signQuestionnaire.filter(
          (x) => x.id !== userId
        ),
      })
    );
  };

  return (
    <TourniquetButtonContent
      backOnClick={handlePrev}
      onAbort={hasQuestionnaire || !allSigned ? onEndTraining : undefined}
      mobileGap={true}
    >
      <TourniquetSlideCard>
        <QcsBox>
          <TourniquetCaption title={t('tourniquet.trainingSingTitle')} />
          {!!tourniquetTrainingSlides.afterTrainingContent &&
            !hasQuestionnaire && (
              <QcsBox sx={{ marginBottom: '1rem' }}>
                <HtmlViewer
                  html={tourniquetTrainingSlides.afterTrainingContent}
                />
              </QcsBox>
            )}
          {!hasQuestionnaire && !allSigned && (
            <SignUser
              playground={tourniquetIdentity.playground}
              userList={tourniquetTraining.signQuestionnaire}
              save={handleSignSave}
            />
          )}
          {(hasQuestionnaire || allSigned) && (
            <QcsBox sx={{ display: 'flex', justifyContent: 'center' }}>
              <QcsLoadingButton
                variant="contained"
                onClick={handleNext}
                loading={loading}
              >
                {hasQuestionnaire ? (
                  <>
                    {t('tourniquet.continueToTest')}
                    <ArrowForward />
                  </>
                ) : (
                  t('common.close')
                )}
              </QcsLoadingButton>
            </QcsBox>
          )}
        </QcsBox>
      </TourniquetSlideCard>
    </TourniquetButtonContent>
  );
};
