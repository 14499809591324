import { VisitationDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  visitationListActions,
  getVisitationList,
  selectVisitationList,
} from '../../store/entities/visitationList';
import { selectIdentity } from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { CellDate } from '../common/grid/CellDate';
import { VisitationFilter } from './VisitationFilter';
import { VisitationAddModal } from './VisitationAddModal';
import { isExternal } from '../../utils/roles';
import {
  getLangNameObj,
  joinStrings2,
} from '../../utils/format';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { useTranslation } from 'react-i18next';
import { VisitationExitModal } from './VisitationExitModal';
import { DateFormat } from '../../utils/date';
import { parseISO } from 'date-fns';

export const Visitation: FC = () => {
  const { t, i18n } = useTranslation();
  const identity = useAppSelector(selectIdentity);
  const visitationList = useAppSelector(selectVisitationList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exitData, setExitData] = useState<VisitationDto>();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getVisitationList(identity.companyId, cancelToken));
  };

  const handleRenderData = (item: VisitationDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item.invitation)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getLangNameObj(i18n, item.invitationPerson)}
        </QcsTableCell>
        <QcsTableCell>{getLangNameObj(i18n, item.supplier)}</QcsTableCell>
        <QcsTableCell>{item.entryPlant}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {joinStrings2(item.workplaces?.map((x) => getLangNameObj(i18n, x)))}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getLangNameObj(i18n, item.invitedBy)}
        </QcsTableCell>
        <CellDate
          value={item.entryTime}
          showWithTime={true}
          hideOnMobile={true}
        />
        <QcsTableCell>
          {!!item.exitTime ? (
            <DateFormat value={parseISO(item.exitTime)} showWithTime={true} />
          ) : (
            <QcsButton onClick={handleShowExitModal(item)} variant="contained">
              {t('visitation.exit')}
            </QcsButton>
          )}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: VisitationDto) => {
    navigate('/visitation/' + item.id);
  };

  const handleAddClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleShowExitModal =
    (item: VisitationDto) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setExitData(item);
    };

  const handleCloseExitModal = () => {
    setExitData(undefined);
  };

  return (
    <>
      <VisitationAddModal open={isModalOpen} onClose={handleModalClose} />
      <VisitationExitModal data={exitData} onClose={handleCloseExitModal} />

      <SectionHeader
        title="visitation.title"
        addText="visitation.add"
        handleAddClick={isExternal(identity) ? undefined : handleAddClick}
      />
      <Grid<VisitationDto>
        headers={[
          { captionStr: 'visitation.invitation', orderName: 'name' },
          {
            captionStr: 'visitation.invitationPerson',
            hideOnMobile: true,
          },
          { captionStr: 'visitation.supplier', orderName: 'supplier' },
          {
            captionStr: 'visitation.establishment',
            orderName: 'establishment',
          },
          {
            captionStr: 'visitation.workplace',
            hideOnMobile: true,
          },
          {
            captionStr: 'visitation.invitedBy',
            hideOnMobile: true,
            orderName: 'employee',
          },
          {
            captionStr: 'visitation.entryTime',
            hideOnMobile: true,
            orderName: 'entryTime',
          },
          {
            captionStr: 'visitation.exitTime',
            orderName: 'exitTime',
          },
        ]}
        data={visitationList}
        gridActions={visitationListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<VisitationFilter query={visitationList.query} />}
      />
    </>
  );
};
