import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  TextField,
  SxProps,
  Theme,
  AutocompleteInputChangeReason,
} from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { DEBOUNCE_TIMEOUT_MS } from '../../../utils/constants';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { ErrorAlert } from '../ErrorAlert';

interface Props<T> {
  sx?: SxProps<Theme>;
  multiple?: boolean;
  selectFirst?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  options?: T[];
  loading: boolean;
  loadingError: boolean;
  loadOptions?: (search: string | undefined, cancelToken: CancelToken) => void;
  getOptionId: (option: T) => string;
  getOptionName: (option: T) => string;
  getValueIfNotFound?: (option: T) => T;
}

export function QcsAutocomplete<T>({
  sx,
  multiple,
  name,
  label,
  disabled,
  required,
  options,
  loading,
  loadingError,
  loadOptions,
  getOptionId,
  getOptionName,
  selectFirst,
  getValueIfNotFound,
}: Props<T>): JSX.Element {
  const [initializing, setInitializing] = useState(true);
  const [open, setOpen] = useState(false);
  const dataCancelToken = useRef<CancelTokenSource | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const {
    values: formikValues,
    touched,
    errors,
    submitCount,
    handleBlur,
    setFieldValue,
  } = useFormikContext<any>();

  const value: T[] | T = _.get(formikValues, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;

  useEffect(() => {
    setInitializing(false);

    if (options && options.length > 0 && options.length === 1 && selectFirst) {
      setFieldValue(name, options[0]);
    }
  
    return () => {
      if (timeoutRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timeoutRef.current);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      dataCancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_event: any, value: T | T[] | null) => {
    setFieldValue(name, value);
  };

  const handleInputChange = (
    _event: any,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    dataCancelToken.current?.cancel();

    if (reason === 'reset') {
      return;
    }

    if (reason === 'clear') {
      if (!open) {
        return;
      }

      dataCancelToken.current = axios.CancelToken.source();
      loadOptions?.(value || undefined, dataCancelToken.current.token);
      return;
    }

    timeoutRef.current = setTimeout(() => {
      dataCancelToken.current?.cancel();
      dataCancelToken.current = axios.CancelToken.source();

      loadOptions?.(value || undefined, dataCancelToken.current.token);
    }, DEBOUNCE_TIMEOUT_MS);
  };

  const handleOpen = (event: React.ChangeEvent<any>) => {
    setOpen(true);
    const value = event?.target.value;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    dataCancelToken.current?.cancel();
    dataCancelToken.current = axios.CancelToken.source();

    loadOptions?.(value || undefined, dataCancelToken.current.token);
  };

  const handleClose = () => {
    setOpen(false);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    dataCancelToken.current?.cancel();
  };

  const filteredOptions = useMemo(() => {
    if (loadingError) {
      return [];
    }

    if (multiple) {
      return [
        ...(options ?? []),
        ...(value as T[]).filter(
          (x) => !options?.map((y) => getOptionId(y)).includes(getOptionId(x))
        ),
      ];
    }

    if (
      value === undefined ||
      value === null ||
      options?.map((x) => getOptionId(x)).includes(getOptionId(value as T))
    ) {
      return options ?? [];
    }

    return [
      ...(options ?? []),
      getValueIfNotFound?.(value as T) ?? (value as T),
    ];
  }, [multiple, getOptionId, options, value, getValueIfNotFound, loadingError]);

  return (
    <Autocomplete
      multiple={multiple}
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        ...sx,
      }}
      options={filteredOptions}
      isOptionEqualToValue={(option, value) =>
        getOptionId(option) === getOptionId(value)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          //Dont use required parameter.
          label={required ? <>{label} *</> : label}
          error={wasTouched && !!errorText}
          inputProps={{
            ...params.inputProps,
          }}
          helperText={wasTouched && errorText}
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            '& .MuiOutlinedInput-root': {
              backgroundColor: theme.palette.common.white,
            },
          })}
        />
      )}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: T) => (
        <li {...props} key={getOptionId(option)}>
          {getOptionName(option)}
        </li>
      )}
      getOptionLabel={(option: T) => getOptionName(option)}
      loading={initializing || loading}
      noOptionsText={loadingError ? <ErrorAlert /> : undefined}
      value={value === undefined ? null : value}
      onChange={handleChange}
      onBlur={handleBlur(name)}
      onInputChange={handleInputChange}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      disabled={disabled}
    />
  );
}
