import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../common/form/Input';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { DatePicker } from '../common/form/DatePicker';
import { SubmitButton } from '../common/form/SubmitButton';
import { EmployeeAutocomplete } from '../common/form/EmployeeAutocomplete';
import { InvitationCreateForm } from '../../models/invitation';
import { InvitationAttachments } from './InvitationAttachments';
import { InvitationButton } from './InvitationButton';
import { InvitationButtonsContainer } from './InvitationButtonsContainer';

interface Props {
  invitationData: InvitationCreateForm;
  handleSubmit: (data: InvitationCreateForm) => void;
  onRemoveOldFile: (document: string) => void;
  onDelete: () => Promise<void>;
}

export const InvitationCreateStep1: FC<Props> = ({
  invitationData,
  handleSubmit,
  onRemoveOldFile,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <FormikDebounce<InvitationCreateForm>
      initialValues={invitationData}
      validationSchema={Yup.object().shape({
        name: validations.stringRequired(t),
        employee: validations.objectRequired(t),
        validFrom: validations.stringDateNotPastRequired(t),
        validTo: Yup.string()
          .nullable()
          .when('validFrom', ([validFrom], schema) =>
            schema.test({
              test: (validTo: string | null | undefined) => {
                return !validTo || validFrom <= validTo;
              },
              message: t('errors.dateToBeforeFrom')!,
            })
          ),
      })}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormContainer>
          <Input
            name="name"
            label={t('invitation.name')}
            maxLength={50}
            required
          />
          <Input
            name="note"
            label={t('invitation.note')}
            maxLength={250}
            multiline={true}
            rows={4}
          />
          <EmployeeAutocomplete
            name="employee"
            label={t('invitation.employee')}
            required
            forInvite={true}
          />
          <DatePicker
            name="validFrom"
            label={t('training.validFrom')}
            disablePast
            required
          />
          <DatePicker
            name="validTo"
            label={t('training.validTo')}
            disablePast
          />
          <Input
            name="noteInternal"
            label={t('invitation.noteInternal')}
            maxLength={250}
            multiline={true}
            rows={4}
          />
          <InvitationAttachments
            titleVariant="h5"
            onRemoveOldFile={onRemoveOldFile}
          />
          <InvitationButtonsContainer>
            <div />
            <div style={{ width: '100%' }} />
            <InvitationButton type="DELETE" customEvent={onDelete} />
            <InvitationButton type="SAVE" />
            <SubmitButton text={t('common.nextStep')} />
          </InvitationButtonsContainer>
        </FormContainer>
      </Form>
    </FormikDebounce>
  );
};
